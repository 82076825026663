<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Main Top Collection List
            <v-spacer></v-spacer>
            <v-row v-if="user.length<=2" justify="end" class="px-5">
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="red" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Main Top Collection</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="link"
                              label=" Link"
                              :rules="[rules.required]"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="title"
                              label="Title"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="caption"
                              label=" Caption"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              dense
                              v-model="collectionname"
                              class="mt-5"
                              label="Sub Category"
                              :items="subcategoryArray"
                              item-text="name"
                              item-value="name"
                              :rules="Rules"
                              required
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-img
                              v-if="imagePreview"
                              :src="imagePreview"
                              width="150px"
                              height="150px"
                              class="align-center"
                              contain
                            /><br />
                            <v-btn outlined @click="$refs.showImage.click()"
                              >Upload Image
                              <v-item-group>
                                <input
                                  v-show="false"
                                  label="Upload Image"
                                  ref="showImage"
                                  id="file"
                                  type="file"
                                  style="display: none"
                                  @change="showImage"
                                />
                                <!-- </v-file-input> -->
                              </v-item-group>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-title>
          <v-layout wrap justify-start pb-4 pt-5>
            <v-flex xs12 sm12 md4 lg4 xl4 text-left pb-2 pb-md-0>
              <v-btn block medium tile outlined color="#FF0000"
                ><span style="font-size: 15px; color: #000000"
                  >Highlighted Collection</span
                ></v-btn
              >
            </v-flex>
            <v-flex xs12 sm12 md8 lg10 xl10 text-left pt-5 pl-md-4>
              <v-layout
                wrap
                justify-start
                align-content-center
                style="line-height: 16px"
              >
                <v-flex xs12 text-uppercase align-self-center>
                  <span style="font-size: 20px; color: #000000"
                    >In top collection</span
                  >
                </v-flex>
                <v-flex xs12 text-uppercase>
                  <span style="font-size: 13px; color: #ff0000"
                    >Special Offer</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div v-if="user.length > 0">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
                  <v-card style="line-height: 16px" tile flat>
                    <div v-viewer style="display: flex">
                      <img
                        style="
                          width: 100%;
                          object-fit: contain;
                          cursor: pointer;
                        "
                        :src="baseURL + item.image"
                        height="300px"
                      />
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="#FF2323"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-layout wrap justify-center px-2>
                      <v-flex pt-2 xl12 md12 xs12 sm12>
                        <span style="font-size: 13px; color: #000000">
                          {{ item.link }} </span
                        ><br /><br />
                        <span style="font-size: 13px; color: #000000">
                          Title: {{ item.title }} </span
                        ><br /><br />
                        <span style="font-size: 13px; color: #000000">
                          Collection Name: {{ item.collectionname }} </span
                        ><br /><br />
                        <span style="font-size: 13px; color: #000000">
                          Caption: {{ item.caption }}
                        </span>
                      </v-flex>
                      <v-flex pt-5 pb-2 xl6 md6 lg6 xs6 sm6 px-4 justify-center>
                        <v-dialog v-model="item.delete" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              block
                              class="mr-2"
                              v-on="on"
                              outlined
                              v-bind="attrs"
                              color="red"
                            >
                              Delete
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              Collection?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <br />
                      </v-flex>
                      <v-flex pt-5 pb-2 xl6 md6 lg6 xs6 sm6 px-4 justify-center>
                        <v-btn
                          small
                          block
                          class="mr-2"
                          outlined
                          color="green"
                          @click="editSlider(item)"
                        >
                          Edit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
          <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span style="font-size: 22px; letter-spacing: 1px">
                  No Data Found...
                  <br />
                  Try adding Data.
                </span>
              </v-flex>
            </v-layout>
          </div>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Main Top Collection</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.link"
                      label=" Link"
                      :rules="[rules.required]"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.title"
                      label=" Title"
                      :rules="Rules"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.caption"
                      label=" Caption"
                      :rules="Rules"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <!-- <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-select
                      dense
                      v-model="editingitem.collectionname"
                      class="mt-5"
                      label="Sub Category"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="name"
                      :rules="Rules"
                      required
                    >
                    </v-select>
                  </v-flex>
                </v-layout> -->
                <v-layout wrap>
                  <v-flex xs12 md12 lg12>
                    <v-img
                      v-if="editingitem.image && !imagePreview"
                      :src="baseURL + editingitem.image"
                      width="400px"
                      height="200px"
                      class="align-center"
                      contain
                    /><br />
                    <v-img
                      v-if="imagePreview"
                      :src="imagePreview"
                      width="400px"
                      height="200px"
                      class="align-center"
                      contain
                    /><br />
                    <v-btn outlined @click="$refs.showImage.click()"
                      >Upload Image
                      <v-item-group>
                        <input
                          v-show="false"
                          label="Upload Image"
                          ref="showImage"
                          id="file"
                          type="file"
                          style="display: none"
                          @change="showImage"
                        />
                        <!-- </v-file-input> -->
                      </v-item-group>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      checkbox: true,
      file: null,
      showPreview: false,
      imagePreview: null,
      showsnackbar: false,
      subcategoryArray: [],
      editingitem: [],
      msg: null,
      pages: 0,
      widthOfCard: "250px",
      link: "",
      caption: "",
      title: "",
      collectionname: "",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      ratings: [],
      dialog: false,
      editdialog: false,

      user: [],
      addslider: false,
      imgId: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted() {
    this.getData();
    this.getSubcategory();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/topcollectionhighlight/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // istop:this.checkbox,
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      axios({
        url: "/subcategory/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          var temp = [];
          temp = response.data.data;
           for (var i = 0; i < temp.length; i++) {
            if (
              temp[i]._id == "5fbf36eba76eedbd31f5f24c" ||
              temp[i]._id == "60a4ba2631b3c87026f6123f" ||
              temp[i]._id == "6096ad5531b3c87026f6119f" ||
               temp[i]._id == "61b7285ded76da75cc2835e9" ||
              temp[i]._id == "61b72897ed76da75cc2835ea" 
            ) {
              this.subcategoryArray.push(temp[i]);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["link"] = this.link;
      data["caption"] = this.caption;
      data["title"] = this.title;
      data["collectionname"] = this.collectionname;
      data["deleteold"]=true;
      axios({
        url: "/home/topcollectionhighlight/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            this.uploadImage(response.data.id);
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.link = null;
            this.caption = null;
            this.title = null;
            this.imagePreview = null;
            this.file = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["link"] = this.editingitem.link;
      user["caption"] = this.editingitem.caption;
      user["title"] = this.editingitem.title;
      user["collectionname"]=this.editingitem.collectionname;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/home/topcollectionhighlight/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.file) {
              this.uploadImage(this.editingitem._id);
            } else {
              this.getData();
              this.$router.push("/home/mainCollection");
              
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/topcollectionhighlight/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.file);

      axios({
        method: "POST",
        url: "/home/topcollectionhighlight/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
             this.link = null;
            this.caption = null;
            this.title = null;
            this.imagePreview = null;
            this.file = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>